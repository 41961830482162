import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { from, NEVER, switchMap, tap } from 'rxjs';
import { CartFeature } from '../../../ecomm/store/features/cart';
import { FeaturesState } from '../../../ecomm/store/types/features-state';

import { Cart } from '../../../ecomm/types/cart.types';

import { BasePageService } from './base-page-service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AsynchronousDispatcher } from '../../../ecomm/utils/asynchronus-dispatcher/asynchronous-dispatcher.service';
import { CurrentUrlService } from '../../../ecomm/utils/current-url/current-url.service';
import { RedirectService } from '../../../ecomm/utils/redirect/redirect.service';
import { StoreSnapshotService } from '../../../ecomm/utils/store-snapshot/store-snapshot.service';
import { CartWorkflowService } from '../../../ecomm/workflows/cart/cart-workflow.service';
import { CustomerWorkflowService } from '../../../ecomm/workflows/customer/customer-workflow.service';
import { StoreInfoWorkflowService } from '../../../ecomm/workflows/store-info/store-info-workflow.service';
import { HandoffMode } from '../../../ecomm/types/selected-handoff-mode.types';

@Injectable()
export class CheckoutPageService extends BasePageService {
  public cart: Cart | undefined | null = null;
  constructor(
    private storeInfoService: StoreInfoWorkflowService,
    private cartService: CartWorkflowService,
    private currentUrlService: CurrentUrlService,
    private storeSnapshotService: StoreSnapshotService,
    @Inject(AsynchronousDispatcher)
    asynchronusDispatcher: AsynchronousDispatcher<FeaturesState>,
    redirectService: RedirectService,
    store: Store,
    userAccountService: CustomerWorkflowService
  ) {
    super(redirectService, store, userAccountService, asynchronusDispatcher);
  }

  loadData$(route: ActivatedRouteSnapshot) {
    return from(this.cartService.getOrCreate(route)).pipe(
      tap((cart) => {
        this.cart = cart;
      }),
      switchMap((cart) =>
        this.storeInfoService.getStoreInfoById(
          cart?.locationId ?? '',
          cart?.handoffMode ?? HandoffMode.carryout,
          false
        )
      ),
      switchMap(() => {
        return from(
          this.storeInfoService.getRegionalConfigurationAndSave(
            this.cart?.handoffMode ?? HandoffMode.carryout
          )
        );
      }),
      switchMap(() => this.storeSnapshotService.get()),
      tap((state) => this.navigateTo(CartFeature.selectCart(state)))
    );
  }

  timeout$ = () => NEVER;

  isInInvalidState(): boolean {
    return false;
  }

  private navigateTo(cart: Cart | null | undefined): void {
    if (cart === null) {
      this.currentUrlService.goToOrderPage(); // navigate to wingstop.com/order
    }
    if (cart && cart?.status !== 'finalized') {
      this.currentUrlService.goToMyBag(); // navigate to my bag page if status not finalized
    }
  }
}
